import { Type, Grid } from '@saladbob/sassafras'

// Create type definitions for the props
interface QuoteProps {
    quote: string;
    author?: string;
    context?: string;
    size?: 'sm' | 'md' | 'lg';
}

const fonts = {
    sm: {
        icon: {
            default: 'h3',
            laptop: 'h1',
        },
        quote: 'p',
        author: 'p',
        context: 'small',
    },
    md: {
        icon: {
            default: 'h1',
            laptop: 'giant',
        },
        quote: {
            default: 'p',
            tablet: 'leader',
            laptop: 'quote',
        },
        author: {
            default: 'p',
            tablet: 'leader',
            laptop: 'quote',
        },
        context: {
            default: 'small',
            laptop: 'p',
        },
    },
    lg: {
        icon: {
            default: 'h1',
            laptop: 'giant',
        },
        quote: {
            default: 'leader',
            laptop: 'h2',
        },
        author: {
            default: 'leader',
            tablet: 'quote',
        },
        context: {
            default: 'p',
            laptop: 'leader',
        },
    },
};

const Quote: React.FC<QuoteProps> = ({ quote, author, context, size }) => {
    return (
        <Grid columns="fit-content(1ch) 1fr" columnGap="md" mb="xl">
            <Type font={fonts[size].icon} style={{ opacity: 0.25 }}>&ldquo;</Type>
            <div>
                <Type font={fonts[size].quote} tag="p">{quote}</Type>
                {author && (
                    <Type
                        font={fonts[size].author}
                        tag="p"
                        mt="xs"
                        style={{ opacity: 0.5 }}
                    >
                        ~ {author}
                    </Type>
                )}
                {context && (
                    <Type font={fonts[size].context}>
                        {context}
                    </Type>
                )}
            </div>
        </Grid>
    )
}

Quote.defaultProps = {
    size: 'md',
};

export default Quote;
