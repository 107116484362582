import React from 'react';
import escapeHTML from 'escape-html';

import {
    Type,
    Box,
    H1,
    H3,
    H4,
    H5,
    H6,
    P,
    A,
    List,
    Li,
    Strong,
} from '@saladbob/sassafras'

type ResponsiveFont = {
    phone: string,
    tablet?: string,
    laptop?: string,
    desktop?: string,
    hd?: string,
};

type Fonts = {
  p?: string | ResponsiveFont,
  h1?: string | ResponsiveFont,
  h2?: string | ResponsiveFont,
  h3?: string | ResponsiveFont,
  h4?: string | ResponsiveFont,
  h5?: string | ResponsiveFont,
  h6?: string | ResponsiveFont,
}

type Node = {
  children: Node[],
  type: string,
  text?: string,
  url?: string,
  value?: any,
  bold?: boolean,
  code?: boolean,
  italic?: boolean,
  textAlign?: string,
};

type Props = (
  children: Node[],
  fonts?: Fonts,
  color?: string,
) => React.ReactNode;

type Slate = {
  content: Node[],
  color?: string,
  fonts: Fonts,
}

const Serialize: Props = (children, f, color) => children.map((node, i) => {
  const fonts = { ...f };

  if (!node) {
    return null;
  }

  if (!node.children && !node.type) {
        let text = <span key={'text-' + i} dangerouslySetInnerHTML={{ __html: escapeHTML(node.text).replace(/(\r\n|\n|\r)/gm, '<br />')}} />;

        if (node.bold) {
          text = (
            <Strong key={`strong-${i}`} weight="bold">
              {text}
            </Strong>
          );
        }

        if (node.code) {
          text = (
            <code key={`code-${i}`}>
              {text}
            </code>
          );
        }

        if (node.italic ) {
          text = (
            <em key={`em-${i}`}>
              {text}
            </em>
          );
        }

        // Handle other leaf types here...

        return text;
  }

  switch (node.type) {
    case 'h1':
      return (
        <H1 mt="0" mb="md" color={color} font={fonts.h1} key={`${node.type}-${i}`} align={ node.textAlign }>
          {Serialize(node.children, f, color)}
        </H1>
      );
    // Iterate through all headings here...
    case 'h2':
        return (
            <H6 mt="0" mb="md" color={color} font={fonts.h2} key={`${node.type}-${i}`}  align={ node.textAlign }>
                {Serialize(node.children, f, color)}
            </H6>
        );
    case 'h3':
        return (
            <H3 mt="0" mb="md" color={color} font={fonts.h3} key={`${node.type}-${i}`} align={ node.textAlign }>
                {Serialize(node.children, f, color)}
            </H3>
        );
    case 'h4':
        return (
            <H4 mt="0" mb="md" color={color} font={fonts.h4} key={`${node.type}-${i}`} align={ node.textAlign }>
                {Serialize(node.children, f, color)}
            </H4>
        );
    case 'h5':
        return (
            <H5 mt="0" mb="md" color={color} font={fonts.h5} key={`${node.type}-${i}`} align={ node.textAlign }>
                {Serialize(node.children, f, color)}
            </H5>
        );
    case 'h6':
        return (
            <H6 mt="0" mb="md" color={color} font={fonts.h6} key={`${node.type}-${i}`} align={ node.textAlign }>
                {Serialize(node.children, f, color)}
            </H6>
        );
    case 'quote':
      return (
        <Type tag="blockquote" font={fonts.p} key={`${node.type}-${i}`} align={ node.textAlign }>
          {Serialize(node.children, f, color)}
        </Type>
      );
    case 'ul':
      return (
        <List mb="md" color={color} key={`${node.type}-${i}`} padding={[0, 0, 0, 'md']} align={ node.textAlign }>
          {Serialize(node.children, f, color)}
        </List>
      );
    case 'ol':
      return (
        <List mb="md" color={color} tag="ol" key={`${node.type}-${i}`} padding={[0, 0, 0, 'md']} align={ node.textAlign }>
          {Serialize(node.children, f, color)}
        </List>
      );
    case 'li':
      return (
        <Li mb="md" color={color} font={fonts.p} key={`${node.type}-${i}`} align={ node.textAlign }>
          {Serialize(node.children, f, color)}
        </Li>
      );
    case 'link':
      return (
        <A
          href={escapeHTML(node.url)}
          key={node.url}
        >
          {Serialize(node.children, f, color)}
        </A>
      );
    case 'upload':
        return (
          <Box textAlign={node.textAlign} key={node.value.id}>
            <img
              src={node.value.sizes?.card?.url || node.value.url} key={node.value.sizes?.card?.url || node.value.url}
              style={{
                width: '100%',
                maxWidth: '400px',
                display: 'inline-block',
              }}
            />
          </Box>
        );

    default:
      return (
        <P mb="md" color={color} font={fonts.p} key={`${node.type}-${i}`} align={node.textAlign}>
          {Serialize(node.children, f, color)}
        </P>
      );
  }
});

const Slate: React.FC<Slate> = ({ content, color, fonts }) => {
    return Serialize(content, fonts, color);
}

export type { Node };

export default Slate;
