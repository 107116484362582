import React from 'react';
import {
    Container, Box, H3, P,
} from '@saladbob/sassafras'

import SponsorItem from './SponsorItem';
import Carousel from '../../media/Carousel';
import { Block } from '../../../types';

const SponsorWall: React.FC<Block> = ({
    sponsors,
    title,
    subtitle,
    buttons,
    dots,
    columns,
    autoplay,
    duration,
    contain,
    verticalPadding: vp,
    horizontalPadding: hp,
    mb,
    txtColor,
    bgColor,
    dotsColor,
    buttonsColor,
    progress,
}) => {
    if (!sponsors || !sponsors.length) {
        return null;
    }

    return (
        <Box mb={mb} padding={{ phone: [vp ? 'md' : '0', hp ? 'md' : '0'], laptop: [vp, hp] }} bgColor={bgColor}>
            <Container size={contain} txtColor={txtColor}>
                {title && (<H3 font="h1" mb="sm" uppercase>{title}</H3>)}
                {subtitle && (<P font="leader" uppercase>{subtitle}</P>)}
                <Carousel
                    columns={columns || 5}
                    buttons={buttons}
                    dots={dots}
                    autoplay={autoplay}
                    progress={progress}
                    duration={duration ? (duration * 1000) : 3000}
                    dotsColor={dotsColor}
                    buttonsColor={buttonsColor}
                >
                    {sponsors.map((sponsor) => (<SponsorItem key={sponsor.id} sponsor={sponsor} /> ))}
                </Carousel>
            </Container>
        </Box>
    )
}

export default SponsorWall;
