import React, { useEffect, useState, useRef, useMemo, useContext, use } from "react";
import { motion, useAnimation, useMotionValue, useTransform } from "framer-motion";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Box, Grid, ThemeContext } from '@saladbob/sassafras'
import { ResponsiveSpacingProps } from "../../types/Spacing";
import { useEnv } from "../providers/EnvProvider";
import { Theme } from "../../types";

type Props = {
    children: React.ReactElement[];
    gap?: number;
    columns?: number;
    buttons?: boolean;
    dots?: boolean;
    autoplay?: boolean;
    progress?: boolean;
    duration?: number;
    buttonsColor?: string;
    dotsColor?: string;
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const Carousel: React.FC<Props & ResponsiveSpacingProps> = ({
    children,
    gap,
    columns: cols,
    buttons,
    dots,
    margin,
    mt,
    mb,
    ms,
    me,
    autoplay,
    progress,
    duration,
    buttonsColor,
    dotsColor,
}) => {
  const { mediaQueries: { tablet, laptop } } = useContext<Theme>(ThemeContext);
  const x = useMotionValue(0)
  const containerRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const touch = useRef(false);
  const timer = useRef(null);
  const controls = useAnimation();
  const [page, setPage] = useState(0);
  const [hovering, setHovering] = useState(false);
  const [dragging, setDragging] = useState(false);
  const {isServer} = useEnv();
  const columns = useMemo(() => {
    if (isServer) {
      return cols;
    }
    if (laptop) {
      return cols;
    }
    if (tablet) {
      return Math.min(cols, 2);
    }
    return 1;
  }, [cols, laptop, isServer]);
  const gapSum =  useMemo(() => (children.length - 1) * gap, [children.length, gap]);
  const containerWidth = useMemo(() => `calc(${(gap * (children.length/columns)) - gap}px + ${100 * children.length/columns}%)`, [children.length, columns, gapSum]);
  const fullPages = useMemo(() => new Array(Math.floor(children.length / columns)).fill(0), [children.length, columns]);

  const currIndex = Math.min(Math.max(0, page), children.length - columns);

  const width = useTransform(x, (latest) => {
    if (!carouselRef.current) return '0%';
    const w = -latest/(carouselRef.current.offsetWidth - containerRef.current.offsetWidth);
    return `${w*100}%`;
  });

  const setTimer = () => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      if (currIndex + 1 > children.length - columns) {
        goTo(0);
      } else {
        next();
      }
    }, duration);
  }

  const killTimer = () => {
    clearInterval(timer.current);
  }

  const paginate = (newDirection: number) => {
    setPage((p) => p + newDirection);
  };

  const prev = () => {
    if (currIndex - 1 < 0) return;
    setPage(page - 1);
    controls.start({ x: -calcX(currIndex - 1) });
  };

  const next = () => {
    if (currIndex + 1 > children.length - columns) return;
    setPage(page + 1);
    controls.start({ x: -calcX(currIndex + 1) });
  };

  const goTo = (index: number) => {
    setPage(index);
    controls.start({ x: -calcX(index) });
  }

  const calcX = (index: number): number => {
    if (!carouselRef.current) return 0;

    const childWidth =
      (carouselRef.current.offsetWidth - gapSum) / children.length;
    return index * childWidth + index * gap;
  };

  useEffect(() => {
    if (autoplay) {
      killTimer();
      setTimer();
    }

    return killTimer;
  }, [page]);

  useEffect(() => {
    if (hovering) {
      killTimer();
    } else if (autoplay) {
      setTimer();
    }
  }, [hovering]);

  useEffect(() => {
    goTo(0);
  }, [laptop]);

  // TODO: Causing server side rendering issues. Need to fix.
  if (children.length <= columns) return (
    <Grid
      columns={`repeat(${children.length}, 1fr)`}
      gridGap={gap}
      margin={margin}
      mt={mt}
      mb={mb}
      ms={ms}
      me={me}
      style={{
        overflowX: "hidden",
        position: "relative",
      }}
    >
      {children}
    </Grid>
  );

  return (
    <Box
      ref={containerRef}
      margin={margin}
      mt={mt}
      mb={mb}
      ms={ms}
      me={me}
      maxWidth="100%"
      style={{
        overflowX: "hidden",
        position: "relative",
      }}
      onMouseOver={() => {
        if (touch.current) return;
        setHovering(true);
      }}
      onMouseOut={() => {
        if (touch.current) return;
        setHovering(false);
      }}
      onTouchStart={() => {
        touch.current = true;
      }}
    >
      <motion.div
        ref={carouselRef}
        drag="x"
        animate={controls}
        transition={{
          type: "spring",
          damping: 40,
          stiffness: 400
        }}
        onDragStart={() => setDragging(true)}
        onDragEnd={(e, { velocity, offset, point }) => {
          if (!carouselRef.current || !containerRef.current) return;

          const swipe = swipePower(offset.x, velocity.x);
          const isRightDirection = offset.x > 45 && velocity.x >= 0;

          /*
            https://github.com/framer/motion/issues/1087
            when touch event by y axis, point is 0 by all axis,
            therfore offset calculates wrong numbers.
          */
          const isPointOkay = point.x !== 0 && point.y !== 0;
          const isLeftDirection =
            offset.x < -45 && velocity.x <= 0 && isPointOkay;

          const childW =
            (carouselRef.current.offsetWidth - gapSum) / children.length;

          const carouselDiments = carouselRef.current.getBoundingClientRect();
          const containerDiments = containerRef.current.getBoundingClientRect();

          const isPassedBoundaries = containerDiments.right > carouselDiments.right - childW;

          let newCurrIndex = currIndex;
          let switchSlideBy = Math.ceil(-offset.x / (childW + gap));

          if (swipe > swipeConfidenceThreshold || isRightDirection) {
            switchSlideBy = switchSlideBy - 1;

            newCurrIndex = currIndex > 0
                ? currIndex + switchSlideBy
                : currIndex;
            if (newCurrIndex < 0) newCurrIndex = 0;

            const indexDiff = newCurrIndex - currIndex;
            if (indexDiff < 0) {
              switchSlideBy = indexDiff;
            }

            if (currIndex > newCurrIndex) {
              paginate(switchSlideBy);
            }
          } else if (swipe > swipeConfidenceThreshold || isLeftDirection) {
            const lastIndex = children.length - 1;

            newCurrIndex =
              currIndex < lastIndex ? currIndex + switchSlideBy : currIndex;
            if (newCurrIndex > lastIndex) newCurrIndex = lastIndex;
            if (isPassedBoundaries) {
              const childrenOnScreen = Math.floor(
                containerRef.current.offsetWidth / childW
              );
              newCurrIndex = children.length - childrenOnScreen;
            }

            const indexDiff = newCurrIndex - currIndex;
            if (switchSlideBy > indexDiff) {
              switchSlideBy = indexDiff;
            }

            if (currIndex < newCurrIndex) {
              paginate(switchSlideBy);
            }
          }

          // if carousel has passed the boundaries of a container
          if (isPassedBoundaries && currIndex <= newCurrIndex) {
            const rightEdge =
              -carouselRef.current.offsetWidth +
              containerRef.current.offsetWidth;
            
            controls.start({ x: rightEdge });
          } else {
            controls.start({ x: -calcX(newCurrIndex) });
          }
          setTimeout(() => setDragging(false), 100);
        }}
        dragConstraints={{ top: 0 }}
        dragElastic={0.2}
        style={{
          display: "flex",
          gap: gap,
          width: containerWidth,
          x,
          alignItems: 'stretch',
        }}
      >
        {children &&
          children.map((child, index) => (
            <div
              key={index}
              role="button"
              style={{
                  width: '100%',
                  pointerEvents: dragging ? 'none' : null,
              }}
            >
              {child}
            </div>
          ))}
      </motion.div>
      {progress && (
        <motion.div 
          style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width,
          }}
        >
          <Box
              height="0.5rem"
              bgColor={buttonsColor || 'secondary'}
              style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
              }}
          />
        </motion.div>
      )}
      {buttons && hovering && (
            <>
                <Box
                    tag="button"
                    height="64px"
                    width="64px"
                    rounded={[0, '32px','32px', 0]}
                    bgColor={buttonsColor || 'secondary'}
                    txtColor="white"
                    verticalAlign="center"
                    align="center"
                    depth="lg"
                    onClick={prev}
                    role="button"
                    style={{
                        position: 'absolute',
                        zIndex: 900,
                        left: '0',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        opacity: currIndex > 0 ? 1 : 0.25,
                    }}
                >
                    <ArrowLeft />
                </Box>
                <Box
                    tag="button"
                    height="64px"
                    width="64px"
                    rounded={['32px', 0, 0, '32px']}
                    bgColor={buttonsColor || 'secondary'}
                    verticalAlign="center"
                    txtColor="white"
                    align="center"
                    depth="lg"
                    onClick={next}
                    role="button"
                    style={{
                        position: 'absolute',
                        zIndex: 900,
                        right: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        opacity: currIndex < children.length - columns ? 1 : 0.25,
                    }}
                >
                    <ArrowRight />
                </Box>
            </>
        )}
        {dots && (
            <Box
                justify="center"
                padding="md"
            >
                {fullPages.map((item, index) => (
                    <Box
                        tag="button"
                        height="24px"
                        width="24px"
                        rounded="24px"
                        margin="xs"
                        bgColor={
                            index * columns < currIndex + 1
                            && index * columns > currIndex - columns
                            ?  dotsColor
                            : 'rgba(255,255,255,0.5)'
                        }
                        bdWidth="4px"
                        bdColor="white"
                        depth="sm"
                        onClick={() => goTo(index * columns) }
                    />
                ))}
            </Box>
        )}
    </Box>
  );
}

Carousel.defaultProps = {
    gap: 32,
    columns: 3,
    duration: 5000,
    dotsColor: 'primary',
    buttonsColor: 'secondary',
};

export default Carousel;