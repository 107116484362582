import React from 'react';
import { Btn } from '@saladbob/sassafras'
import { ArrowLeft, ArrowRight, ArrowLeftCircle, ArrowRightCircle } from 'react-feather';

import Responsive from '../../../types/Responsive';

type Props = {
    text: string,
    size?: string,
    color?: string,
    icon?: string,
    full?: boolean,
    outline?: boolean,
    onClick?: () => void,
    marginBottom?: Responsive,
};

const icons = {
    ArrowLeft: () => (<ArrowLeft />),
    ArrowRight: () => (<ArrowRight />),
    ArrowRightCircle: () => (<ArrowRightCircle />),
    ArrowLeftCircle: () => (<ArrowLeftCircle />),
}

const fonts = {
    sm: 'p',
    md: 'leader',
    lg: 'h2',
    xl: 'h1',
    xxl: 'jumbo',
}

const Button: React.FC<Props> = ({ text, size, color, icon, full, outline, onClick, marginBottom }) => (
    <Btn
        size={size}
        font={fonts[size]}
        onClick={onClick}
        iconRight={icons[icon] ? icons[icon]() : undefined}
        color={color}
        full={full}
        outline={outline}
        mb={marginBottom}
    >
        {text}
    </Btn>
);

Button.defaultProps = {
    size: 'md',
};

export default Button;
