import React from 'react';
import {
    Box,
    H1,
} from '@saladbob/sassafras'

import Gallery from '../../media/Gallery';
import { Upload } from '../../../types';

type GalleryItem = {
    id: string,
    image: Upload,
};

type Props = {
    gallery: GalleryItem[],
    height?: string,
    // title?: string;
    // subtitle?: string;
};

const GalleryBlock: React.FC<Props> = ({ gallery, height }) => {
    if (!gallery || !gallery.length) {
        return null;
    }

    return (
        <Box>
            <Gallery autoplay gallery={gallery} duration={5000} height={height} aspectRatio="1" />
        </Box>
    )
}

GalleryBlock.defaultProps = {
    height: 'auto',
}

export default GalleryBlock;
