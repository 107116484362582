import React from "react";
import { Container, Grid, Box } from '@saladbob/sassafras'

import Slate from "../../Slate";
import { Upload } from "../../../types";

type Props = {
    content: any,
    contain?: string,
    mb?: string,
    size?: string,
    verticalPadding?: string,
    horizontalPadding?: string,
    bgColor?: string,
    txtColor?: string,
    minHeight?: string,
    images?: {
        id: string,
        image: Upload,
    }[],
    imageGap?: string,
    imageStyle?: string,
    imageMinWidth?: string,
}

const fonts = {
    sm: {
        p: 'small',
        h1: 'h3',
        h2: 'h4',
        h3: 'h5',
        h4: 'h6',
        h5: 'h6',
        h6: 'p',
    },
    md: {
        p: 'p',
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
    },
    leader: {
        p: 'leader',
        h1: 'jumbo',
        h2: 'h1',
        h3: 'h2',
        h4: 'h3',
        h5: 'h4',
        h6: 'h5',
    },
    lg: {
        p: 'leader',
        h1: 'giant',
        h2: 'jumbo',
        h3: 'h1',
        h4: 'h2',
        h5: 'h3',
        h6: 'h4',
    },
    xl: {
        p: 'h3',
        h1: 'giant',
        h2: 'jumbo',
        h3: 'h1',
        h4: 'h2',
        h5: 'h3',
        h6: 'h4',
    },
    xxl: {
        p: 'h2',
        h1: 'giant',
        h2: 'jumbo',
        h3: 'h1',
        h4: 'h2',
        h5: 'h3',
        h6: 'h4',
    }
}

const ImageColumns: React.FC<Props> = ({
    content,
    size,
    mb,
    verticalPadding: vp,
    horizontalPadding: hp,
    contain,
    bgColor,
    txtColor,
    minHeight,
    images,
    imageGap,
    imageStyle,
    imageMinWidth,
}) => {
    const columns = imageMinWidth
        ? `repeat(auto-fit, minMax(${imageMinWidth}, 1fr))`
        : 'repeat(auto-fit, minMax(200px, 1fr))';

    return (
        <Box
            mb={mb}
            minHeight={minHeight}
            padding={{ phone: [vp, hp ? 'md' : 0], laptop: [vp, hp] }}
            bgColor={bgColor}
            txtColor={txtColor}
            align="center"
        >
            <Container size={contain} height="min-content">
                <Box mb={{ phone: 'md', laptop: 'xxl' }}>
                    {content && content.length && (<Slate content={content} fonts={fonts[size]} />)}
                </Box>
                <Grid columns={columns} gridGap={imageGap}>
                    {images && images.map((image) => (
                        <Box
                            key={image.image.sizes.card.url}
                            depth="lg"
                            rounded="100%"
                            overflow="hidden"
                        >
                            <img
                                src={image.image.sizes.card.url}
                                alt={image.image.name || 'Image'}
                                style={{
                                    aspectRatio: '1/1',
                                }}
                            />
                        </Box>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}

ImageColumns.defaultProps = {
    size: 'md',
    verticalPadding: 'md',
    horizontalPadding: 'md',
    imageMinWidth: '200px',
};

export default ImageColumns;
