import React from 'react';
import { PiMapPinLine } from 'react-icons/pi';
import Link from 'next/link';
import { 
    Box, 
    Type,
} from '@saladbob/sassafras'

type Props = {
    host: {
        slug: string,
        name: string,
    },
    txtColor?: string,
    font?: string,
}

const fonts = {
    labels: {
        phone: 'small',
        laptop: 'p',
    },
};

const HostTag: React.FC<Props> = ({ host, txtColor, font }) => {
    return (<Link href={`/host/${host.slug}`}>
        <Box display="inline-flex" align="center" txtColor={txtColor || 'rgba(0, 0, 0, 0.5)'} me="md">
            <PiMapPinLine size="24px" />
            <Type font={font || fonts.labels} ms="xs" uppercase>
                {host.name}
            </Type>
        </Box>
    </Link>
)}

export default HostTag;