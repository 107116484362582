import React from 'react';
import {
    Box,
    H1,
} from '@saladbob/sassafras'

import Gallery from '../../media/Gallery';
import { Upload } from '../../../types';

type GalleryItem = {
    id: string,
    image: Upload,
};

type Props = {
    title: string;
    subtitle: string;
    graphic?: Upload;
    gallery: GalleryItem[],
};

const fonts = {
    title: {
        phone: 'leader',
        laptop: 'large',
    },
    subtitle: {
        phone: 'h1',
        laptop: 'jumbo',
    },
}

const HeroHeader: React.FC<Props> = ({ title, subtitle, graphic, gallery}) => {
    if (!gallery || !gallery.length) {
        return null;
    }

    return (
        <Box>
            <Gallery autoplay gallery={gallery} priority={true} loading={undefined} duration={5000} parallax height="calc(100vh - 120px)"/>
            <Box
                height="100%"
                justify="flex-end"
                align="flex-end"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 3,
                }}
            >
                <Box width="auto" padding="lg">
                    {graphic && (
                        <Box ms="auto" width="20vw" minWidth="120px" mb="md">
                            <img src={graphic.url} alt={`${title || graphic.name } Image`} />
                        </Box>
                    )}
                    <H1 color="tertiary" font={fonts.title} mb="0" align="right">{title}</H1>
                    <H1 color="white" font={fonts.subtitle}>{subtitle}</H1>
                </Box>
            </Box>
        </Box>
    )
}

export default HeroHeader;
