import dynamic from 'next/dynamic';
import HeroHeader from './HeroHeader/HeroHeader';
import Quote from './Quote/Quote';
import YTVideo from './YTVideo/YTVideo';
import RichText from './RichText/RichText';
import Button from './Button/Button';
import WorldTour from './WorldTour/WorldTour';
import FeaturedVideo from './FeaturedVideo/FeaturedVideo';
import PostSlider from './PostSlider/PostSlider';
import SponsorWall from './SponsorWall/SponsorWall';
import HofSlider from './HofSlider/HofSlider';
import Gallery from './Gallery/Gallery';
import ImageText from './ImageText/ImageText';
import ImageColumns from './ImageColumns/ImageColumns';


type Props = {
    blocks: Array<{
        blockType: string,
        id: string,
    }>,
    size?: string,
    actions?: object,
};

const types = {
    sponsorWall: ({ key, blockType, sponsors, ...props }) => (
        <SponsorWall
            key={key}
            id={key}
            blockType={blockType}
            sponsors={sponsors}
            {...props}
        />
    ),
    worldTour: ({ key, title, pdf, tournaments, map }) => (
        <WorldTour key={key} title={title} pdf={pdf} tournaments={tournaments} map={map} />
    ),
    heroHeader: ({ key, title, subtitle, graphic, gallery }) => (
        <HeroHeader key={key} title={title} subtitle={subtitle} graphic={graphic} gallery={gallery} />
    ),
    quote: ({ key, quote, author, context, size }) => (
        <Quote
            key={key}
            quote={quote}
            author={author}
            context={context}
            size={size}
        />
    ),
    postslider: ({ key, blockType, posts,...props}) => (
        <PostSlider
            key={key}
            id={key}
            blockType={blockType}
            posts={posts}
            {...props}
        />
    ),
    richText: ({ key, content, ...props }) => (<RichText key={key} content={content} {...props} />),
    imageText: ({ key, content, ImageTextSize, size, ...props }) => (<ImageText key={key} size={ImageTextSize} content={content} {...props} />),
    imageColumns: ({ key, content, ImageColumnsSize, size, ...props }) => (<ImageColumns key={key} size={ImageColumnsSize} content={content} {...props} />),
    button: ({
        key,
        actions,
        text,
        size,
        color,
        action,
        actionValue,
        icon,
        outline,
        full,
        marginBottom,
    }) => {
        const onClick = actions && actions[action]
            ? () => actions[action](actionValue)
            : undefined;

        return (
            <Button
                key={key}
                size={size}
                color={color}
                onClick={onClick}
                text={text}
                outline={outline}
                full={full}
                icon={icon}
                marginBottom={marginBottom}
            />
        );
    },
    featuredVideo: ({ key, ...props }) => (
        <FeaturedVideo
            key={key}
            {...props}
        />
    ),
    YouTubeVideo: ({ key, youtube, height, width }) => (<YTVideo key={key} id={youtube} height={height} width={width} />),
    hofslider: ({ key, hofs, blockType, ...props }) => (
        <HofSlider
            id={key}
            key={key}
            hofs={hofs}
            blockType={blockType}
            {...props}
        />
    ),
    gallery: ({ key, gallery, height }) => (
        <Gallery
            key={key}
            gallery={gallery}
            height={height}
        />
    ),
}

const Blocks: React.FC<Props> = ({ blocks, size, actions }) => {
    return blocks
        ? blocks.map((block, i) => types[block.blockType] ? types[block.blockType]({key: block.id, size, actions, ...block }): null)
        : blocks;
}
export default Blocks;
