import React from 'react';
import { Box } from '@saladbob/sassafras'

type Props = {
    videoId: string,
    depth?: string,
    title: string,
}

const style = {
    width: '100%',
    aspectRatio: '16/9',
}

const YouTube: React.FC<Props> = ({ videoId, depth, title }) => {
    return (
        <Box depth={depth}>
            <iframe
                width="100%"
                title={`${title} YouTube Video`}
                src={`https://www.youtube.com/embed/${videoId}?rel=0&amp;showinfo=0`}
                allow="accelerometer; fullscreen; autoplay; encrypted-media; gyroscope;"
                style={style}
            />
        </Box>
    )
}

export default YouTube;