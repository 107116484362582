import React from 'react';

type Props = {
    html: string
};

const Html: React.FC<Props> = ({ html }) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: html }} />
    )
}

export default Html;