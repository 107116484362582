import React from "react";
import { Container, Grid, Box } from '@saladbob/sassafras'

import Image from "../../media/Image";
import Slate from "../../Slate";
import { Upload } from "../../../types";

type Props = {
    content: any,
    contain?: string,
    mb?: string,
    size?: string,
    verticalPadding?: string,
    horizontalPadding?: string,
    bgColor?: string,
    txtColor?: string,
    minHeight?: string,
    image?: Upload,
    imagePosition?: string,
}

const fonts = {
    sm: {
        p: 'small',
        h1: 'h3',
        h2: 'h4',
        h3: 'h5',
        h4: 'h6',
        h5: 'h6',
        h6: 'p',
    },
    md: {
        p: 'p',
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
    },
    leader: {
        p: 'leader',
        h1: 'jumbo',
        h2: 'h1',
        h3: 'h2',
        h4: 'h3',
        h5: 'h4',
        h6: 'h5',
    },
    lg: {
        p: 'leader',
        h1: 'giant',
        h2: 'jumbo',
        h3: 'h1',
        h4: 'h2',
        h5: 'h3',
        h6: 'h4',
    },
    xl: {
        p: 'h3',
        h1: 'giant',
        h2: 'jumbo',
        h3: 'h1',
        h4: 'h2',
        h5: 'h3',
        h6: 'h4',
    },
    xxl: {
        p: 'h2',
        h1: 'giant',
        h2: 'jumbo',
        h3: 'h1',
        h4: 'h2',
        h5: 'h3',
        h6: 'h4',
    }
}

const ImageText: React.FC<Props> = ({
    content,
    size,
    mb,
    verticalPadding: vp,
    horizontalPadding: hp,
    contain,
    bgColor,
    txtColor,
    minHeight,
    image,
    imagePosition,
}) => {
    if (!content) return null;
    return (
        <Box
            mb={mb}
            minHeight={minHeight}
            padding={{ phone: [vp, hp ? 'md' : 0], laptop: [vp, hp] }}
            bgColor={bgColor}
            txtColor={txtColor}
            align="center"
        >
            <Container size={contain} height="min-content">
                <Grid columns="repeat(auto-fit, minMax(400px, 1fr))" gridGap="lg">
                    <Box order={ imagePosition === 'right' ? 2 : 0 }>
                        <Image image={image} size="hd" alt="image" />
                    </Box>
                    <Box padding={{ phone: 'md', laptop: 'xl' }} maxWidth="640px">
                        <Slate content={content} fonts={fonts[size]} />
                    </Box>
                </Grid>
            </Container>
        </Box>
    )
}

ImageText.defaultProps = {
    size: 'md',
    verticalPadding: 'md',
    horizontalPadding: 'md',
    contain: '100%',
};

export default ImageText;
