import React from "react";
import Link from "next/link";
import { Play } from "react-feather";
import { Box, H4, Grid } from '@saladbob/sassafras'

import { Hof } from "../../types";
import Image from "../media/Image";

type Props = {
    hof: Hof,
    depth?: string,
    bgColor?: string,
    txtColor?: string,
    year?: boolean,
}


const HofCard: React.FC<Props> = ({ hof, bgColor, txtColor, year, depth }) => {
    return (
        <Grid 
            columns="1fr"
            rows="min-content 1fr"
            minHeight="auto"
            height="100%"
            depth={depth}
        >
            <Image
                image={hof.image}
                size="card"
                alt={hof.caption}
                loading="lazy"
                aspectRatio="1/1"
            />
            <Box padding="md" verticalAlign="center" bgColor={bgColor || 'tertiary'}>
                <H4 color={txtColor || 'black'}>{hof.caption}</H4>
                {year && (<H4 font="p" mb="0" mt="auto" ms="auto" color={txtColor || 'black'} style={{ opacity: 0.5 }}>{hof.year}</H4>)}
            </Box>
        </Grid>
    );
}

export default HofCard;