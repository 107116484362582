import React from 'react';
import Link from 'next/link';
import {
    Box,
    Grid,
    H4,
} from '@saladbob/sassafras'

import Image from 'next/image';

import { Post } from '../../../types';

type Props = {
    post: Post,
};

const PostItemColumn: React.FC<Props> = ({ post }) => {
    return (
        <Grid 
            columns="100%"
            rows="min-content 1fr"
            height="100%"
        >
            <Box>
                <Box
                    maxWidth="100%"
                    bgColor={post.featuredImage.color}
                >
                    <img
                        src={post.featuredImage.sizes.card.url}
                        alt={post.featuredImage.name || 'Featured Image'}
                        draggable={false}
                        style={{
                            aspectRatio: '4/3',
                            objectFit: 'cover',
                        }}
                    />
                </Box>
            </Box>
            <Box overflow="hidden">
                <Box verticalAlign="center" bgColor={'tertiary'} height="100%" padding="md">
                    <H4 display="inline" color="black">
                        <Link href={`/news/${post.slug}`} draggable={false}>
                            {post.title}
                        </Link>
                    </H4>
                </Box>
            </Box>
        </Grid>
    )
}

export default PostItemColumn;