import React from 'react';
import YouTube from '../../media/YouTube';

import {
    Box,
} from '@saladbob/sassafras'

// Create types for props
type Props = {
    id: string,
    height?: string,
    width?: string,
    title?: string,
};

const YTVideo: React.FC<Props> = ({ height, width, id, title}) => {
    return (
        <Box width={width} height={height} verticalAlign="center" mb="lg">
            {id && <YouTube title={title} videoId={id} />}
        </Box>
    )
};

YTVideo.defaultProps = {
    height: '360',
    width: '640',
};

export default YTVideo;
