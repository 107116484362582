import React from 'react';
import {
    Box,
    H2,
    H3,
    Container,
} from '@saladbob/sassafras'

import Html from '../../Html';
import YouTube from '../../media/YouTube';

// Create types for props
type Props = {
    title?: string,
    subtitle?: string,
    youtube?: string,
    videoEmbed?: string,
    backgroundColor?: string,
    titleFont?: string,
    subtitleFont?: string,
    titleColor?: string,
    subtitleColor?: string,
    verticalPadding?: string,
    horizontalPadding?: string,
    marginBottom?: string,
    mb?: string,
    contain?: string,
    minHeight?: string,
};

const titleMargin = {
    giant: 'xl',
    jumbo2: 'xl',
    jumbo: 'lg',
    h1: 'md',
    h2: 'md',
    h3: 'md',
    h4: 'sm',
    h5: 'sm',
    h6: 'sm',
    leader: 'sm',
    p: 'sm',
}

const FeaturedVideo: React.FC<Props> = ({
    title,
    subtitle,
    youtube,
    videoEmbed,
    backgroundColor,
    titleColor,
    subtitleColor,
    verticalPadding: vp,
    horizontalPadding: hp,
    mb,
    titleFont,
    subtitleFont,
    contain,
    minHeight,
}) => {
    return (
        <Box
            minHeight={minHeight}
            bgColor={backgroundColor}
            mb={mb}
            padding={{ phone: [vp, hp ? 'md' : 0], laptop: [vp, hp] }}
            align="center"
            bgImage="/images/b-ball-texture-r.svg"
            style={{
                backgroundAttachment: 'fixed',
            }}
        >
            <Container size={contain} height="min-content">
                {title && (
                    <H2
                        font={{ phone: 'h1', laptop: titleFont }}
                        align="center"
                        color={titleColor}
                        mb={{ phone: 'sm', laptop: titleMargin[titleFont] || 'sm' }}
                        uppercase
                    >
                        {title}
                    </H2>
                )}
                {subtitle && <H3 font={{ phone: 'h1', laptop: subtitleFont }} color={subtitleColor} uppercase>{subtitle}</H3>}
                {youtube && <YouTube title={title} videoId={youtube} />}
                {videoEmbed && <Box depth="xl"><Html html={videoEmbed} /></Box>}
            </Container>
        </Box>
    )
};

FeaturedVideo.defaultProps = {
    verticalPadding: 'md',
    horizontalPadding: 'md',
}

export default FeaturedVideo;
