import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeft, ArrowRight } from 'react-feather';
import {
    Box,
    Btn,
} from '@saladbob/sassafras'

type Props = {
    children: any[],
    autoplay?: boolean,
    duration?: number,
    step?: number,
    buttons?: boolean,
    dots?: boolean,
    bgColor?: string,
    dotsColor?: string,
    buttonsColor?: string,
    height?: object | string,
};

// Wrap index if it goes outside of bounds
const wrap = (min, max, v) => {
    const rangeSize = max - min;
    return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0
      };
    }
};

const swipeConfidenceThreshold = 10000;

const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

const Slider: React.FC<Props> = ({
    children,
    height,
    autoplay,
    duration,
    step,
    buttons,
    dots,
    bgColor,
    dotsColor,
    buttonsColor,
}) => {
    const [[page, direction], setPage] = useState([step || 0, 0]);
    const [dragging, setDragging] = useState(false);
    const timer = useRef(null);

    // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
    // then wrap that within 0-2 to find our image ID in the array below. By passing an
    // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
    // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
    const itemIndex = wrap(0, children.length, page);

    const setTimer = () => {
        if (autoplay) {
            timer.current = setTimeout(next, duration);
        }
    }

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
    };

    const next = () => {
        return paginate(1);
    }

    const prev = () => {
        return paginate(-1);
    }

    const goTo = (index) => {
        setPage([index, index > page ? 1 : -1]);
    };
    
    useEffect(() => {
        clearTimeout(timer.current);
        if (autoplay) {
            timer.current = setTimeout(next, duration);
        }
        return () => clearTimeout(timer.current);
    }, [page]);

    useEffect(() => {
        if (step && step !== page) {
            goTo(step);
        }
    }, [step]);

    return (
        <Box
            height={ height || '640px'}
            style={{ overflow: 'hidden' }}
            bgColor={bgColor}
        >
            <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    key={page}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: {
                            type: "spring",
                            stiffness: 300,
                            damping: 30,
                            duration: 0.3,
                        },
                        opacity: { duration: 0.3 }
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragStart={() => {
                        clearTimeout(timer.current);
                        setDragging(true);
                    }}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);
            
                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                            paginate(-1);
                        } else {
                            setTimer();
                        }
                        setTimeout(() => setDragging(false), 100);
                    }}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                    }}
                >   
                    <div style={{
                        pointerEvents: dragging ? 'none' : 'all',
                        height: '100%',
                    }}
                    >
                        {children[itemIndex]}
                    </div>
                </motion.div>
            </AnimatePresence>
            {buttons && (
                <>
                    <Box
                        tag="button"
                        height="64px"
                        width="64px"
                        rounded={[0, '32px','32px', 0]}
                        bgColor={buttonsColor}
                        txtColor="white"
                        verticalAlign="center"
                        align="center"
                        depth="lg"
                        onClick={prev}
                        style={{
                            position: 'absolute',
                            zIndex: 900,
                            left: '0',
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <ArrowLeft />
                    </Box>
                    <Box
                        tag="button"
                        height="64px"
                        width="64px"
                        rounded={['32px', 0, 0, '32px']}
                        bgColor={buttonsColor}
                        verticalAlign="center"
                        txtColor="white"
                        align="center"
                        depth="lg"
                        onClick={next}
                        style={{
                            position: 'absolute',
                            zIndex: 900,
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <ArrowRight />
                    </Box>
                </>
            )}
            {dots && (
                <Box
                    justify="center"
                    style={{ position: 'absolute', zIndex: 900, bottom: '32px' }}
                >
                    {children.map((item, index) => (
                        <Box
                            key={index}
                            tag="button"
                            height="32px"
                            width="32px"
                            rounded="32px"
                            margin="xs"
                            bgColor={index === itemIndex ? dotsColor : 'rgba(255,255,255,0.5)'}
                            bdWidth="4px"
                            bdColor="white"
                            depth="sm"
                            onClick={() => goTo(index) }
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

Slider.defaultProps = {
    duration: 5000,
    autoplay: true,
    buttons: false,
    dots: true,
    bgColor: 'tertiary',
    buttonsColor: 'tertiary',
    dotsColor: 'tertiary',
};

export default Slider;