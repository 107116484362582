import React, { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import {
    Box,
    Grid,
    Type,
    ThemeContext,
    P,
} from '@saladbob/sassafras'

import TimeTag from '../../tags/TimeTag';
import HostTag from '../../tags/HostTag';
import TournamentTag from '../../tags/TournamentTag';
import { Post } from '../../../types';

type Props = {
    post: Post,
    txtBgColor?: string,
    txtColor?: string,
    initial?: boolean,
};

interface ThemeContextProps {
    mediaQueries: {
        laptop: boolean;
        // other media queries
    };
}

const variants = {
    imageInit: {
        x: 0,
        scale: 1,
    },
    imageEnter: (laptop) => {
        return {
            x: laptop ? -50 : 0,
            scale: 1.05,
        };
    },
    textInit: {
        x: 0,
    },
    textEnter: (laptop) => {
        return {
            x: laptop ? -80 : 0,
        };
    },
};

const PostItem: React.FC<Props> = ({ post, txtBgColor, txtColor, initial }) => {
    const { mediaQueries } = useContext<{mediaQueries}>(ThemeContext);

    const laptop = mediaQueries.laptop;

    return (
        <Grid
            columns={{ phone: '1fr', laptop: '3fr 2fr' }}
            rows={{ phone: 'auto minmax(min-content, 50%)', laptop: '1fr' }}
            gap="none"
            height="100%"
        >
            <AnimatePresence initial={initial} custom={laptop}>
                <motion.div
                    variants={variants}
                    initial="imageInit"
                    animate="imageEnter"
                    exit="imageInit"
                    transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                    }}
                    custom={laptop}
                    style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Box
                        height="100%"
                        align="flex-end"
                        justify="center"
                        bgColor={post.featuredImage ? post.featuredImage.color : null}
                    >
                        {post.featuredImage && (
                            <img
                                src={post.featuredImage.sizes.hd.url}
                                alt={post.featuredImage.name || 'Post Featured Image'}
                                draggable={false}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        )}
                    </Box>
                </motion.div>
                <Box
                    padding={{ laptop: ['lg', 'xxl', 'lg', 'lg'] }}
                    txtColor={txtColor}
                    verticalAlign="center"
                    style={{
                        userSelect: 'none',
                    }}
                >
                    <motion.div
                        variants={variants}
                        initial="textInit"
                        animate="textEnter"
                        exit="textInit"
                        custom={laptop}
                        transition={{
                            duration: 0.6,
                            ease: "easeInOut",
                        }}
                    >
                        <Box bgColor={txtBgColor} padding="md">
                            {(post.tournaments || post.hosts) && 
                                <Box mb="xs" align="center">
                                    {post.tournaments && post.tournaments.map((tournament) => (
                                        <TournamentTag tournament={tournament} />
                                    ))}
                                    {post.hosts && post.hosts.map((host) => (
                                        <HostTag host={host} />
                                    ))}
                                </Box>
                            }
                            <Link href={`/news/${post.slug}`} draggable={false}>
                                <Type
                                    tag="h2"
                                    mb="sm"
                                    weight="bold" color={txtColor || 'black'}
                                    font={{ phone: 'h5', laptop: 'h1', hd: 'jumbo' }}
                                >
                                        {post.title}
                                </Type>
                            </Link>
                            <Box>
                                <TimeTag date={post.createdAt} />
                            </Box>
                        </Box>
                    </motion.div>
                    <Box mt="md" display={{ phone: 'none', laptop: 'block' }}>
                        <P font="leader">
                            {post.excerpt}
                        </P>
                    </Box>
                </Box>
            </AnimatePresence>
        </Grid>
    )
}

PostItem.defaultProps = {
    txtBgColor: 'tertiary',
    txtColor: 'black',
};

export default PostItem;