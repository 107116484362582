import React from 'react';
import {
    Container, Box, H3, P,
} from '@saladbob/sassafras'

import { useEnv } from '../../providers/EnvProvider';
import Slider from '../../media/Slider';
import PostItem from './PostItem';
import PostItemColumn from './PostItemColumn';
import Carousel from '../../media/Carousel';
import { Block } from '../../../types';

const PostSlider: React.FC<Block> = ({
    posts,
    title,
    subtitle,
    columns,
    buttons,
    dots,
    autoplay,
    duration,
    contain,
    verticalPadding: vp,
    horizontalPadding: hp,
    gap,
    mb,
    progress,
    bgColor,
    dotsColor,
    buttonsColor,
    txtColor,
    titleBgColor,
}) => {
    const { headerHeight } = useEnv();

    if (!posts || !posts.length) {
        return null;
    }

    const getSlider = () => {
        if (columns && columns > 1) {
            return (
                <Carousel
                    columns={columns}
                    progress={progress}
                    buttons={buttons}
                    dots={dots}
                    gap={gap}
                    buttonsColor={buttonsColor}
                    dotsColor={dotsColor}
                >
                    {posts.map((post) => (<PostItemColumn key={post.id} post={post} /> ))}
                </Carousel>
            );
        }
        return (
            <Slider
                buttons={ buttons }
                dots={dots}
                autoplay={autoplay}
                duration={duration ? (duration * 1000) : 5000}
                bgColor={bgColor || ''}
                dotsColor={dotsColor || ''}
                buttonsColor={buttonsColor || ''}
                height={{ phone: `calc(100vh - ${headerHeight}px)`, laptop: `calc(100vh - ${headerHeight}px)` }}
            >
                {posts.map((post) => (
                    <PostItem
                        key={post.id}
                        post={post}
                        txtColor={txtColor}
                        txtBgColor={ titleBgColor }
                        initial={true}
                    />
                ))}
            </Slider>
        )
    }

    return (
        <Box mb={mb} padding={{ phone: [vp ? 'md' : 0, hp ? 'md' : 0], laptop: [vp, hp] }} bgColor={bgColor}>
            <Container size={contain}>
                {title && (<H3 font="h1" mb="sm" uppercase>{title}</H3>)}
                {subtitle && (<P font="leader" uppercase>{subtitle}</P>)}
                {getSlider()}
            </Container>
        </Box>
    )
}

export default PostSlider;
