import React from 'react';
import {
    Box,
} from '@saladbob/sassafras'

import Image from '../../media/Image';

import { Sponsor } from '../../../types/Host';

type Props = {
    sponsor: Sponsor,
    txtBgColor?: string,
    txtColor?: string,
    initial?: boolean,
};

const SponsorItem: React.FC<Props> = ({ sponsor }) => {
    return (
        <a href={sponsor.url} title={sponsor.name} draggable={false} target="_blank">
            <Box
                align="flex-end"
                justify="center"
                bgColor={sponsor.bgColor}
            >
                    {sponsor.logo && (
                        <Image
                            image={sponsor.logo}
                            alt={`${sponsor.name} logo`}
                            size="card"
                            aspectRatio="1/1"
                            objectFit="contain"
                            
                        />
                    )}
            </Box>
        </a>
    )
}

SponsorItem.defaultProps = {
    txtBgColor: 'tertiary',
    txtColor: 'black',
};

export default SponsorItem;