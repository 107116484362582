import React, { useState, useEffect, useRef } from 'react';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import {
    Box,
} from '@saladbob/sassafras'

import { Upload } from '../../types';

type GalleryItem = {
    id: string,
    image: Upload,
}

type SlideProps = {
    slide: GalleryItem,
    y: any,
    duration: number,
    index: number,
    size?: string,
    loading?: 'lazy' | 'eager',
    priority?: boolean,
}

type GalleryProps = {
    height?: string,
    gallery: GalleryItem[],
    autoplay?: boolean,
    duration?: number,
    step?: number,
    size?: string,
    parallax?: boolean,
    aspectRatio?: string,
    loading?: 'lazy' | 'eager' | null,
    priority?: boolean,
}

const variants = {
    initial: {
        clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 150% 100%)',
    },
    enter: {
        clipPath: 'polygon(-50% 0%, 100% 0%, 100% 100%, 0% 100%)',
    },
    ginit: {
        clipPath: 'polygon(150% 0%, 100% 0%, 100% 100%, 100% 100%)',
    },
    genter: {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, -50% 100%)',
    }
}

function useParallax(value, distance) {
    return useTransform(value, [0, 1], [0, distance]);
}

const Slide: React.FC<SlideProps> = ({
    slide,
    y,
    duration,
    size,
    index,
    loading,
    priority,
}) => {
    if (!slide.image?.url) {
        return null;
    }
    return (
        <motion.div
            variants={variants}
            initial="initial"
            animate="enter"
            exit="enter"
            transition={{
                duration: 0.5,
                delay: 0.3,
            }}
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: '0',
                zIndex: index,
            }}
        >
            <AnimatePresence initial={true}>
                <motion.div
                    initial={{
                        scale: 1.1,
                    }}
                    animate={{
                        scale: 1.25,
                    }}
                    transition={{
                        duration: (duration / 1000) + 1,
                        ease: 'linear',
                    }}
                    style={{
                        width: '100%',
                        height: '100%',
                        y,
                    }}
                >
                    <Box
                        height="100%"
                    >
                        <Image
                            src={slide.image.sizes[size || 'hd'].url}
                            placeholder={slide.image.blurDataUrl ? 'blur' : 'empty'}
                            blurDataURL={slide.image.blurDataUrl}
                            fill={true}
                            loading={loading}
                            priority={priority}
                            alt={slide.id}
                            style={{
                                objectFit: 'cover',
                            }}
                        />
                    </Box>
                </motion.div>
            </AnimatePresence>
        </motion.div>
    )
};

const Gallery: React.FC<GalleryProps> = ({
    height,
    parallax,
    loading,
    gallery,
    aspectRatio,
    size,
    autoplay,
    duration,
    priority,
    step: propStep
}) => {
    const [step, setStep] = useState(0);
    const { scrollYProgress } = useScroll();
    const [slides, setSlides] = useState([0, 0]);
    const timer = useRef(null);
    const y = useParallax(scrollYProgress, 300);

    const next = () => {
        if (step === gallery.length - 1) {
            setSlides([gallery.length - 1, 0]);
            return setStep(0);
        }

        setSlides([step, step + 1]);
        setStep(step + 1);
    }

    useEffect(() => {
        clearTimeout(timer.current);
        if (autoplay) {
            timer.current = setTimeout(next, duration);
        }
        return () => clearTimeout(timer.current);
    }, [step]);

    useEffect(() => {
        if (propStep && propStep !== step) {
            setStep(propStep);
        }
    }, [propStep]);

    return (
        <Box height={ height || '640px'} style={{ overflow: 'hidden', zIndex: 0, aspectRatio }}>
            <motion.div
                key={step}
                variants={variants}
                initial="ginit"
                animate="genter"
                transition={{ duration: 0.5 }}
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: '0',
                    backgroundColor: 'yellow',
                    zIndex: 1,
                }}
            />
            <AnimatePresence initial={false}>
                <Slide size={size} loading={loading} key={gallery[slides[0]].id + '+1'} slide={gallery[slides[0]]} y={parallax ? y : 0} duration={duration} index={0} priority={priority} />
                <Slide size={size} loading={loading} key={gallery[slides[1]].id + '+2'} slide={gallery[slides[1]]} y={parallax ? y : 0} duration={duration} index={1} priority={priority} />
            </AnimatePresence>
        </Box>
    );
};

Gallery.defaultProps = {
    duration: 5000,
};

export default Gallery;