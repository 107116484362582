import React from 'react';
import { PiCalendarBlankBold } from 'react-icons/pi';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { 
    Box, 
    Type,
} from '@saladbob/sassafras'

dayjs.extend(relativeTime);


type Props = {
    date: string,
    txtColor?: string,
    font?: string,
}

const fonts = {
    labels: {
        phone: 'small',
        laptop: 'p',
    },
};

const TimeTag: React.FC<Props> = ({ date, txtColor, font }) => (
    <Box display="inline-flex" align="center" txtColor={ txtColor || 'rgba(0, 0, 0, 0.5)'} me="md">
        <PiCalendarBlankBold size="24px" />
        <Type font={font || fonts.labels} ms="xs" uppercase>
            {dayjs(date).fromNow()}
        </Type>
    </Box>
);

export default TimeTag;